import React, { useState, useEffect, useRef } from 'react'

const LocationFinder = ({lat, lng, value, city}) => {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(city);

  useEffect(() => {
    if(!lat || !lng){
      if (navigator.geolocation) {
        navigator.geolocation?.getCurrentPosition((position) => {
          if (position?.coords?.latitude && position?.coords?.longitude) {
            window.location = window.location + `?ul=${position.coords.latitude},${position.coords.longitude}`
          }
        }, (err) => console.error(err) );
      }
    }
  }, [])

  return (
    <h2 className="text-center mt-3 mb-5 font-weight-bold">
      Find Fencing Events near&nbsp;
      <form action="/" method="get" style={{ display: 'inline', width: 100, borderBottom: '2px solid #000', cursor: 'pointer', textWrap: 'nowrap'}}>
        <input 
          ref={inputRef}
          type="text" 
          name="ul" 
          value={inputValue} 
          onChange={e => setInputValue(e.target.value)}
          style={{ width: 240, background: 'transparent', border: 0, outline: 'none', fontWeight: 700 }}
          placeholder="Pick a city..." 
        /> 
        <i className="fa fa-fw fa-pencil" style={{ fontSize: 20 }} onClick={() => {
          setInputValue('');
          inputRef?.current?.focus()
        }} />
      </form>
      
    </h2>
  );
}

export default LocationFinder;


