import 'promise-polyfill/src/polyfill';

import React from 'react'
import ReactDOM from 'react-dom'
import Tablesort from 'tablesort';

import ClubSelect from '../components/club-select';
import DivisionFilter from '../components/division-filter';
import DivisionSelect from '../components/division-select';
import UsfaMembershipEntry from '../components/usfa-membership-entry'
import CffMembershipEntry from '../components/cff-membership-entry'
import TeamPreregistration from '../views/team-preregistration';
import StripeCheckout from '../views/stripe-checkout';
import StripeCheckoutResult from '../views/stripe-checkout-result';
import FencerSelectReload from '../views/fencer-select-reload';
import FencerSelect from '../components/fencer-select';
import LocationFinder from '../components/location-finder';
import AuthorityFilter from '../components/authority-filter';
import HomeMap from '../views/home-map';
import EventSelect from '../components/event-select';
import MyFencers from '../components/my-fencers';
import { MapContainer } from '../components/map-component';
import TournamentResultsProcessing from "../components/tournament-results-processing";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const authorityFilterLoader = () => {
  const containers = document.getElementsByTagName('authority-filter');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <AuthorityFilter isResults={container.getAttribute("is-results")}  />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', authorityFilterLoader)
authorityFilterLoader();

const locationFinderLoader = () => {
  const containers = document.getElementsByTagName('location-finder');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <LocationFinder 
          lat={container.getAttribute("lat")} 
          lng={container.getAttribute("lng")} 
          value={container.getAttribute("value")} 
          city={container.getAttribute("city")} 
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', locationFinderLoader)
locationFinderLoader();

const homeMapLoader = () => {
  const containers = Array.from(document.getElementsByTagName('home-map'));

  containers.forEach(container => {
    if(container) {
      ReactDOM.render(
        <MapContainer
          latitude={container.getAttribute("lat")}
          longitude={container.getAttribute("lng")}
          isMobile={!!container.getAttribute("is-mobile")}
          isBot={!!container.getAttribute("is-bot")}
        >
          <HomeMap
            isMobile={!!container.getAttribute("is-mobile")}
            isBot={!!container.getAttribute("is-bot")}
          />
        </MapContainer>,
        container,
      )
    }
  });
}

document.addEventListener('turbolinks:load', homeMapLoader)
homeMapLoader();

const usfaMembershipEntryLoader = () => {
  const containers = document.getElementsByTagName('usfa-membership-entry');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <UsfaMembershipEntry 
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', usfaMembershipEntryLoader)
usfaMembershipEntryLoader();

const cffMembershipEntryLoader = () => {
  const containers = document.getElementsByTagName('cff-membership-entry');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <CffMembershipEntry 
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', cffMembershipEntryLoader)
cffMembershipEntryLoader();


const clubSelectLoader = () => {
  const containers = document.getElementsByTagName('club-select');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <ClubSelect 
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
          isClearable={container.getAttribute("is-clearable")} 
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', clubSelectLoader)
clubSelectLoader();


const divisionSelectLoader = () => {
  const containers = document.getElementsByTagName('division-select');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <DivisionSelect 
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
          isClearable={container.getAttribute("is-clearable")}
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', divisionSelectLoader)
divisionSelectLoader();


const divisionFilterLoader = () => {
  const containers = document.getElementsByTagName('division-filter');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <DivisionFilter
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', divisionFilterLoader)
divisionFilterLoader();


const eventSelectLaoder = () => {
  const containers = document.getElementsByTagName('event-select');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <EventSelect
          name={container.getAttribute("name")} 
          value={container.getAttribute("value")} 
          noTeam={!!container.getAttribute("no-team")}
          onlyTeam={!!container.getAttribute("only-team")}
          single={container.getAttribute("single")}
          tournamentId={container.getAttribute("tournament-id")}
        />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', eventSelectLaoder)
eventSelectLaoder();


const teamPreregistrationLoader = () => {
  const containers = document.getElementsByTagName('team-preregistration');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <TeamPreregistration preregistrationId={container.getAttribute("preregistration-id")} />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', teamPreregistrationLoader)
teamPreregistrationLoader();



const stripeCheckoutLoader = () => {
  const containers = document.getElementsByTagName('stripe-checkout');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <StripeCheckout
          connectedStripeAccountId={container.getAttribute("connected-stripe-account-id")}
          intentUrl={container.getAttribute("intent-url")}
          successUrl={container.getAttribute("success-url")}
         />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', stripeCheckoutLoader)
stripeCheckoutLoader();

const stripeCheckoutResultLoader = () => {
  const containers = document.getElementsByTagName('stripe-checkout-result');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <StripeCheckoutResult
          connectedStripeAccountId={container.getAttribute("connected-stripe-account-id")}
          paymentIntentClientSecret={container.getAttribute("payment-intent-client-secret")}
         />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', stripeCheckoutResultLoader)
stripeCheckoutResultLoader();



const fencerSelectReloadLoader = () => {
  const containers = document.getElementsByTagName('fencer-select-reload');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <FencerSelectReload
          name={container.getAttribute("name")}
          fencerId={container.getAttribute("fencer-id")}
         />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', fencerSelectReloadLoader)
fencerSelectReloadLoader();

const fencerSelectLoader = () => {
  const containers = document.getElementsByTagName('fencer-select');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <FencerSelect
          name={container.getAttribute("name")}
          fencerId={container.getAttribute("fencer-id")}
          fencerValue={container.getAttribute("fencer-value")}
          isClearable={container.getAttribute("is-clearable")}
         />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', fencerSelectLoader)
fencerSelectLoader();


const myFencersLoader = () => {
  const containers = document.getElementsByTagName('my-fencers');
  
  for(var i = 0; i < containers.length; i++ ){
    const container = containers[i];
    if( !!container ){
      ReactDOM.render(
        <MyFencers id={container.getAttribute("id")} />,
        container,
      )
    }
  }
}

document.addEventListener('turbolinks:load', myFencersLoader)
myFencersLoader();


Tablesort.extend('usfaRating', function(item) {
  return /([A-EU]{1}(\d{4}|))/.test(item);
}, function(aStart, bStart) {
  const a = aStart.trim();
  const b = bStart.trim();
  const aLetter = a[0];
  const bLetter = b[0];
  const aYear = a.substr(1,4);
  const bYear = b.substr(1,4);
  
  if( aLetter === bLetter ){
    return ('' + bYear).localeCompare(aYear)
  } else {
    return ('' + aLetter).localeCompare(bLetter)
  }
});

const tablesortLoader = () => {
  const tables = document.getElementsByClassName('tablesort');
  for(var i = 0; i < tables.length; i++ ){
    new Tablesort(tables[i], {descending: true});
  }
}

document.addEventListener('turbolinks:load', tablesortLoader)
tablesortLoader();

const tournamentResultsProcessingLoader = () => {
  const containers = Array.from(document.getElementsByTagName('tournament-results-processing'));

  containers.forEach(container => {
    if(container) {
      ReactDOM.render(
        <TournamentResultsProcessing
          filename={container.getAttribute("filename")}
          tournamentId={container.getAttribute("tournament_id")}
        />,
        container,
      )
    }
  });
}

document.addEventListener('turbolinks:load', tournamentResultsProcessingLoader)
tournamentResultsProcessingLoader();